@font-face {
  font-family: 'Favorit Trial Std';
  src: url('./fonts/FavoritStd-Book.woff') format('woff'),
    url('./fonts/FavoritStd-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

#veronica {
  font-family: PT Serif;
font-style: normal;
font-weight: normal;
margin: 0;
}

.intro.intro1 {
  padding-top: 230px;
  padding-bottom: 155px;
  
}


html body {
  font-family: assistant;
  margin: 0;
  padding: 0;
  background-color: #E7DBCA;
}

  
#prev-kristall {
  /* display: none; */
  display: block;
position: absolute;
width: 290px;
height: 431px;
left: 680px;
top: 3088px;
transition: all 0.4s ease-in-out;
  opacity: 0;
}

#prev-kristall.hovered {
  opacity: 1;
display: block;
position: absolute;
width: 290px;
height: 431px;
left: 680px;
top: 3088px;
transition: all 0.4s ease-in-out;
z-index: -1;
}


#prev-rupp {
  display: block;
position: absolute;
left: 140px;
width: 344px;
opacity: 0;
transition: all 0.4s ease-in-out;
}

#prev-rupp.hovered {
opacity: 1;
display: block;
position: absolute;
left: 140px;
width: 344px;
transition: all 0.4s ease-in-out;
}

#prev-loftwerk {
  opacity: 0;
  display: block;
  position: absolute;
  width: 414px;
  left: 132px;
  top: 3303px;
  transition: all 0.4s ease-in-out;
}


#prev-loftwerk.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 414px;
  left: 132px;
  top: 3303px;
  transition: all 0.4s ease-in-out;
}

#prev-binetiq {
  opacity: 0;
display: block;
position: absolute;
width: 344px;
left: 507px;
transition: all 0.4s ease-in-out;
}

#prev-binetiq.hovered {
  opacity: 1;
display: block;
position: absolute;
width: 344px;
left: 507px;
transition: all 0.4s ease-in-out;
}

#prev-voigt {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  right: 200px;
  top: 3247px;
  transition: all 0.4s ease-in-out;
}

#prev-voigt.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  right: 200px;
  top: 3247px;
  transition: all 0.4s ease-in-out;
}

#prev-mycs {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  left: 350px;
  top: 3443px;
  transition: all 0.4s ease-in-out;
}

#prev-mycs.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  left: 350px;
  top: 3443px;
  transition: all 0.4s ease-in-out;
}

#prev-illus {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  left: 680px;
  top: 3253px;
  transition: all 0.4s ease-in-out;
}

#prev-illus.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  left: 680px;
  top: 3253px;
  transition: all 0.4s ease-in-out;
}

p {
  margin: 0;
  padding: 0;
}

html {
  cursor: url(./images/cursor.png) 6 6, auto;
  cursor: -webkit-image-set (url ('images/cursor.png') 1x, url ('images/cursor-2.png') 2x) 6 6, auto;
}

a {
  color: #001AFF;
}
a#linkedin-contact, a#instagram-contact, a#visualux {
  color: #001AFF;
}

a:visited {
  color: #001AFF;
}

a:hover {
  cursor: url(./images/cursor-hover.svg) 6 6, auto;	
  cursor: -webkit-image-set (url ('images/cursor-hover.png') 1x, url ('images/cursor-2-hover.png') 2x) 6 6, auto;

  fill: #001AFF;
  text-decoration: underline;
  color: #001AFF;
}

#visualux:hover {
  color: #001AFF;
}


.menu-clickable:hover, .back-to-top:hover, .download-button:hover, button:hover, #logo:hover{
  cursor: url(./images/cursor-hover.svg) 6 6, auto;
  cursor: -webkit-image-set (url ('images/cursor-hover.png') 1x, url ('images/cursor-2-hover.png') 2x) 6 6, auto;
}


.download-button:hover {
  text-decoration: none;
}
.download-button:focus, button:focus, .download-button:visited, button:visited {
  text-decoration: none;
  border: 2px solid #001AFF;
  color: #001AFF;
}

button:focus, button:active, a.download-button:focus, a.download-button:active {
  outline: none !important;
  box-shadow: none;
  border: none;
}

a.no-style-anchor {
    text-decoration: none;
    color: inherit;
}

a:hover.no-style-anchor {
  color: #001AFF;
}



#open-menu a {
  color: white;
}


.intro {
    font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
}

a#visual-ux-designer {
    text-decoration: none;
}

a:link#visual-ux-designer {
  color: #0000EE;
  border-bottom: 3px solid #202020;
}

/* .intro2 { */
  /* position: absolute;
  width: 954px;
  height: 548px;
  left: 243px;
  top: 1405px; */
  
  /* Copy 1 */
  
  /* font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px; */
  
  /* #202020 */
  
  /* color: #202020;
} */

h2#about {
  /* position: absolute;
  width: 254px;
  height: 41px;
  left: 593px;
  top: 1335px; */

/* H2 */

font-family: 'PT Serif';
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 42px;
text-align: center;

/* #202020 */

color: #202020;
}

/* h2#latest-works {
  position: absolute;
  width: 254px;
  height: 41px;
  left: 593px;
  top: 2960px;
} */
/* 
h2#colophon {
  position: absolute;
width: 254px;
height: 41px;
left: 593px;
top: 4294px;
} */

h3 {
font-family: PT Serif;
font-style: italic;
font-weight: normal;
font-size: 26px;
line-height: 34px;

/* #202020 */

color: #202020;
}
/* 
h3#expertise {
position: absolute;
width: 254px;
height: 41px;
left: 243px;
top: 2067px;
} */
/* 
.complementary {
  position: absolute;
width: 254px;
height: 41px;
left: 243px;
top: 2356px;
} */


.work-details{
  font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
}

button {
font-family: Favorit Trial Std;
font-style: normal;
/* font-weight: bold; */
font-size: 26px;
line-height: 31px;
text-align: center;
border-width: medium;
border: 2px solid #001AFF;
color: #001AFF;
background: none;
height: 68px;
}

.download-button {
  font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 26px;
color: #001AFF;
}

a.download-button {
  color: #001AFF;
}

#rupp {
  /* position: absolute;
width: 490px;
height: 95px;
left: 225px;
top: 3073px; */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;

/* #202020 */

color: #202020;
}

#kristall-baeder {
  /* position: absolute;
width: 438px;
height: 95px;
left: 777px;
top: 3073px; */

/* cursor: url(./images/preview-kristall-baeder.svg), pointer; */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;

/* #202020 */

color: #202020;
}

#loftwerk {
  /* position: absolute;
  width: 288px;
  height: 95px;
  left: 193px;
  top: 3218px;
   */
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 84px;
  
  /* #202020 */
  
  color: #202020;
}

#binetiq {
/* position: absolute;
width: 235px;
height: 95px;
left: 531px;
top: 3218px; */

/* H1 */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;

/* #202020 */

color: #202020;
}

#galerie-voigt {
  /* position: absolute;
width: 432px;
height: 95px;
left: 816px;
top: 3218px; */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;

/* #202020 */

color: #202020;
}

#mycs {
  /* position: absolute;
width: 174px;
height: 95px;
left: 410px;
top: 3363px; */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;
text-align: center;

/* #202020 */

color: #202020;
}

#illustrations {
  /* position: absolute;
width: 412px;
height: 95px;
left: 632px;
top: 3366px; */

font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 70px;
line-height: 84px;
text-align: center;

/* #202020 */

color: #202020;
}

.colophon {
  font-family: PT Serif;
font-style: italic;
font-weight: normal;
font-size: 26px;
line-height: 34px;

/* #202020 */

color: #202020;
}
/* 
#design {
  position: absolute;
width: 254px;
height: 41px;
left: 243px;
top: 4407px;
} */

#concept {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* position: absolute;
  width: 528px;
  height: 20px;
  left: 487px;
  top: 4428px; */
}

/* #development {
  position: absolute;
width: 254px;
height: 41px;
left: 243px;
top: 4518px;
} */

#developer {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  /* position: absolute;
  width: 710px;
  height: 20px;
  left: 487px;
  top: 4539px; */
}

/* #copywriting {
  position: absolute;
  width: 254px;
  height: 41px;
  left: 243px;
  top: 4602px;
} */

#copywriter {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* position: absolute;
  width: 710px;
  height: 22px;
  left: 487px;
  top: 4627px; */
}

/* #typography {
  position: absolute;
  width: 254px;
  height: 41px;
  left: 243px;
  top: 4692px;
} */

#font {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* position: absolute;
  width: 710px;
  height: 21px;
  left: 487px;
  top: 4717px; */
}

/* #technology {
  position: absolute;
  width: 254px;
  height: 41px;
  left: 243px;
  top: 4776px;
} */

#react {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* position: absolute;
  width: 710px;
  height: 21px;
  left: 487px;
  top: 4801px; */
}

/* #moral-support {
  position: absolute;
  width: 254px;
  height: 41px;
  left: 243px;
  top: 4866px;
} */

#supporters {
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* position: absolute;
  width: 710px;
  height: 141px;
  left: 487px;
  top: 4891px;   */
}

#prev-kristall, #prev-kristall.hovered {
  display: none;
}

#prev-rupp, #prev-rupp.hovered {
  display: none;
}

#prev-loftwerk, #prev-loftwerk.hovered {
  display: none;
}

#prev-binetiq, #prev-binetiq.hovered {
  display: none;
}

#prev-voigt, #prev-voigt.hovered {
  display: none;
}

#prev-mycs, #prev-mycs.hovered {
  display: none;
}

#prev-illus, #prev-illus.hovered {
  display: none;
}

/* #illustrations:hover > #prev-illus {
  display: block;
  position: absolute;
  width: 344px;
  height: 511px;
  left: 824px;
  top: 2833px;
} */

#latest-works{
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
}
.row.row-no-padding > [class*="col-"]{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#expertise, .complementary {
  font-family: PT Serif;
  font-style: italic;
  font-weight: normal;
}



@media (max-width: 576px) {
  .row.row1{
    margin: auto;
  }
  #rupp, #kristall-baeder, #binetiq, #loftwerk, #binetiq, #galerie-voigt, #mycs, #illustrations {
    text-align: center;
    font-size: 63px;
    line-height: 73px;
  }
  .intro{
    font-family: Favorit Trial Std;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px; 
    font-size: 24px;
    line-height: 29px;
    padding: 30px 30px 0px 30px;
  }

.intro.intro1 {
    padding: 64px 30px 40px 30px;
    font-size: 38px;
    line-height: 50px;
}

.about-second {
  padding-top: 20px;
}

h2{
  z-index: 8;
  padding-top: 30px;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
}
#h2-latest-works{
  padding-bottom: 20px;
  font-family: PT Serif;
font-style: normal;
font-weight: normal;
}
h2#colophon {
  font-family: PT Serif;
font-style: normal;
font-weight: normal;
  padding-top: 70px;
  text-align: center;
  padding-bottom: 20px;
}

#expertise, .complementary {
  font-size: 22px;
line-height: 29px;
padding-top: 60px;
padding-bottom: 20px;
text-align: center;
}

button {
  width: 100%;
}

#latest-works div p{
  text-align: center;
  font-size: 36px;
  line-height: 42px;
  padding-top: 15px;
  margin:0px;
  
}
.work-details {
  font-size: 22px;
line-height: 29px;
padding: 0px 30px 0px 30px;
}
.button {
  padding: 50px 30px 32px 30px;
  font-size: 22px;
  line-height: 26px;
}
.colophon {
  padding: 10px 15px 0px 15px;
  margin:0px;
}
#design, #development, #copywriting, #typography, #technology, #moral-support {
padding-top: 10px;
}
.colophon-headings {
  padding: 0px 30px 0px 30px;
  font-size: 24px;
  line-height: 32px;
  }

}


@media (min-width: 1200px) {
  

  .row.row1 {
    margin: auto;
  }
  #rupp{
    text-align: center;
    padding-right: 30px;
  }
  #mycs {
    text-align: center;
    padding-right: 30px;
  }
  #kristall {
    text-align: center;
    padding-left: 69px;
  }

  #illustrations {
    padding-left: 42px;
    text-align: center;
  }

#binetiq{
  padding-left: 38px;
}

#loftwerk {
  width: 362px;
  /* padding-right: 104px; */
}


#prev-kristall {
  /* display: none; */
  display: block;
position: absolute;
width: 290px;
height: 431px;
left: 680px;
top: 3088px;
transition: all 0.4s ease-in-out;
  opacity: 0;
}

#prev-kristall.hovered {
  opacity: 1;
display: block;
position: absolute;
width: 290px;
height: 431px;
left: 680px;
top: 3088px;
transition: all 0.4s ease-in-out;
z-index: -1;
}


#prev-rupp {
  display: block;
position: absolute;
left: 140px;
width: 344px;
opacity: 0;
transition: all 0.4s ease-in-out;
}

#prev-rupp.hovered {
opacity: 1;
display: block;
position: absolute;
left: 140px;
width: 344px;
transition: all 0.4s ease-in-out;
}

#prev-loftwerk {
  opacity: 0;
  display: block;
  position: absolute;
  width: 414px;
  left: 132px;
  top: 3303px;
  transition: all 0.4s ease-in-out;
}


#prev-loftwerk.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 414px;
  left: 132px;
  top: 3303px;
  transition: all 0.4s ease-in-out;
}

#prev-binetiq {
  opacity: 0;
display: block;
position: absolute;
width: 344px;
left: 507px;
transition: all 0.4s ease-in-out;
}

#prev-binetiq.hovered {
  opacity: 1;
display: block;
position: absolute;
width: 344px;
left: 507px;
transition: all 0.4s ease-in-out;
}

#prev-voigt {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  right: 200px;
  top: 3247px;
  transition: all 0.4s ease-in-out;
}

#prev-voigt.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  right: 200px;
  top: 3247px;
  transition: all 0.4s ease-in-out;
}

#prev-mycs {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  left: 350px;
  top: 3443px;
  transition: all 0.4s ease-in-out;
}

#prev-mycs.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  left: 350px;
  top: 3443px;
  transition: all 0.4s ease-in-out;
}

#prev-illus {
  opacity: 0;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  left: 680px;
  top: 3253px;
  transition: all 0.4s ease-in-out;
}

#prev-illus.hovered {
  opacity: 1;
  display: block;
  position: absolute;
  width: 290px;
  height: 431px;
  left: 680px;
  top: 3253px;
  transition: all 0.4s ease-in-out;
}

  /* #collaborations {
    padding: 0px 160px 73px 160px;
  } */

  .veronicaportrait {
    padding: 0px 323px 107px 323px !important;
  }
  
  .hovereffect {
  overflow: hidden;
}

.hovereffect img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hovereffect img {
  display: block;
  position: relative;
  transform: scale(1);
}

.hovereffect:hover img {
  transform: scale(1.2)
}
  .portrait {
    padding: 0px 326px 107px 326px !important;
  } 
  .intro.intro1{
    padding: 230px 169px 95px 169px;
    font-size: 53px;
line-height: 70px;
  }
  h2#about h2 {
    padding-top: 30px;
    font-size: 32px;
    line-height: 42px;
  }
  .intro{
    font-size: 50px;
    line-height: 60px;
    padding: 60px 169px 95px 169px;
  }
  .about-second{
    padding-top: 50px;
  }
.my-facts {
  padding: 60px 169px 95px 169px;
}
.work-details {
  font-size: 50px;
  line-height: 60px;
  padding-left: 7px;
/* padding: 20px 30px 0px 30px; */
}
#expertise, .complementary {
  font-size: 26px;
  line-height: 9px;
  padding-top: 25px;
  
}
.complementary {
  padding-top:66px;
  }

  .button {
    padding: 0px 458px 108px 458px;
  }
  button {
    width: 285px;
  }
  .download-button {
    font-size: 26px;
    line-height: 31px;
  }
  #h2-latest-works{
    z-index: 8;
  text-align: center;
  padding-bottom: 60px;
  margin:0;
  font-size: 32px;
  line-height: 42px;
  }
  #rupp, #kristall-baeder, #binetiq, #loftwerk, #binetiq, #galerie-voigt, #mycs, #illustrations {
    text-align: center;
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 20px;
    z-index:8;
  }
  #kristall-baeder, #illustrations {
    text-align: left;
  }
  #mycs {
    text-align: right;
  }

  .colophon-details {
    font-size: 24px;
  line-height: 29px;
  padding: 52px 169px 95px 169px;
  }
  #design, #development, #copywriting, #typography, #technology, #moral-support {
    font-family: PT Serif;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 8px;
    }
#concept, #developer, #copywriter, #font, #react, #supporters {
  padding-bottom: 43px;
}
h2#colophon {
  padding-top: 60px;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
}
}

@media (min-width: 1440px) {
  
#prev-kristall {
  left: 781px;
  top: 2863px;
}
#prev-kristall.hovered{
  left: 781px;
  top: 2863px;
}

#prev-rupp, #prev-rupp.hovered {
  left: 206px;
}

#prev-loftwerk, #prev-loftwerk.hovered {
  left: 132px;
  top: 3116px;
}
/* 
#binetiq, #prev-binetiq.hovered {

} */

#prev-voigt, #prev-voigt.hovered {
  right: 200px;
  top: 2987px;
}

#prev-mycs, #prev-mycs.hovered {
  left: 350px;
  top: 3139px;
}

#prev-illus, #prev-illus.hovered {
  left: 815px;
  top: 3179px;
}
   .intro.intro1{
    padding: 230px 98px 95px 98px;
  }
  .intro, .my-facts{
    padding-left: 98px;
    padding-right: 98px;
  }
}

@media (min-width: 1500px) {
#prev-kristall, #prev-kristall.hovered {
  left: 879px;
  top: 2851px;
}

#prev-rupp, #prev-rupp.hovered {
  left: 201px;
}


#prev-loftwerk, #prev-loftwerk.hovered {
  left: 145px;
  top: 3147px;
}


#prev-voigt, #prev-voigt.hovered {
  top: 3024px;
}

#prev-mycs, #prev-mycs.hovered {
  left: 410px;
  top: 3220px;
}

#prev-illus, #prev-illus.hovered {
  left: 818px;
  top: 3158px;
}
}

@media (min-width: 1700px) {
  
#prev-mycs, #prev-mycs.hovered {
  left: 496px;
}
#prev-voigt, #prev-voigt.hovered {
  right: 355px;
}
}



@media (min-width: 1800px) {
  
  #prev-mycs, #prev-mycs.hovered {
    left: 551px;
  }

  #prev-rupp, #prev-rupp.hovered {
    left: 400px;
}
  #prev-illus, #prev-illus.hovered {
    left: 984px;
  }
  #prev-voigt, #prev-voigt.hovered {
    right: 401px;
    top: 2911px;
  }
  #prev-binetiq, #prev-binetiq.hovered {
  left: 619px;
  }
  
  #prev-loftwerk, #prev-loftwerk.hovered {
    left: 275px;
  }
  #prev-kristall, #prev-kristall.hovered {
    left: 1033px;
  }
  
}


/* Portrait */
/* @media only screen 

  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .intro{
      font-family: Favorit Trial Std;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px; 
      font-size: 24px;
      line-height: 29px;
      padding: 30px 30px 0px 30px;
    }
  
  .intro.intro1 {
      padding: 64px 30px 40px 30px;
      font-size: 38px;
      line-height: 50px;
  }
  
  h2{
    z-index: 8;
    padding-top: 30px;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
  }
  #h2-latest-works{
    padding-bottom: 20px;
    font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  }
  h2#colophon {
    font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
    padding-top: 70px;
    text-align: center;
    padding-bottom: 20px;
  }
  
  #expertise, .complementary {
    font-size: 22px;
  line-height: 29px;
  padding-top: 60px;
  padding-bottom: 20px;
  text-align: center;
  }
  
  button {
    width: 100%;
  }
  
  #latest-works div p{
    text-align: center;
    font-size: 32px;
    line-height: 42px;
    padding-top: 15px;
    margin:0px;
    
  }
  .work-details {
    font-size: 24px;
  line-height: 29px;
  padding: 0px 30px 0px 30px;
  }
  .button {
    padding: 50px 30px 32px 30px;
    font-size: 22px;
    line-height: 26px;
  }
  .colophon {
    padding: 10px 15px 0px 15px;
    margin:0px;
  }
  #design, #development, #copywriting, #typography, #technology, #moral-support {
  padding-top: 20px;
  }
  .colophon-headings {
    padding: 0px 30px 0px 30px;
    font-size: 26px;
    line-height: 34px;
    }
  
    .intro.intro1 {
      padding-top: 30px;
      padding-bottom: 30px;
  }
  button {
  width: 100%;
  }
  #galerie-voigt {
    text-align: center !important;
  }
  } */


  @media only screen and (device-width: 768px) {
 .intro{
    font-family: Favorit Trial Std;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px; 
    font-size: 24px;
    line-height: 29px;
    padding: 30px 30px 0px 30px;
  }

  .intro.intro1 {
    padding: 186px 30px 40px 30px;
    font-size: 38px;
    line-height: 50px;
}

h2{
  z-index: 8;
  padding-top: 30px;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
}
#h2-latest-works{
  padding-bottom: 20px;
  font-family: PT Serif;
font-style: normal;
font-weight: normal;
}
h2#colophon {
  font-family: PT Serif;
font-style: normal;
font-weight: normal;
  padding-top: 70px;
  text-align: center;
  padding-bottom: 20px;
}

#expertise, .complementary {
  font-size: 22px;
line-height: 29px;
padding-top: 60px;
padding-bottom: 20px;
text-align: center;
}

button {
  width: 100%;
}

#latest-works div p{
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  padding-top: 15px;
  margin: 0px;
  
}
.work-details {
  font-size: 24px;
line-height: 29px;
padding: 0px 30px 0px 30px;
}
.button {
  padding: 50px 30px 32px 30px;
  font-size: 22px;
  line-height: 26px;
}
.colophon {
  padding: 10px 15px 0px 15px;
  margin:0px;
}
#design, #development, #copywriting, #typography, #technology, #moral-support {
padding-top: 20px;
}
.colophon-headings {
  padding: 0px 30px 0px 30px;
  font-size: 24px;
  line-height: 34px;
  }

  }
  



  @media only screen and (device-width: 1024px) {
    #kristall-baeder {
      position: relative;
      right: 110px;
    }

    #rupp {
      position: relative;
    left: 108px;
    }
   #galerie-voigt {
    position: relative;
    right: 47px;
  }
  #loftwerk {
    position: relative;
    left: 35px;
  }
  #mycs{
    position: relative;
    left: 133px;
   }
   #illustrations {
    position: relative;
    right: 53px;
   }
    #expertise, .complementary.col-12 {
      font-size: 22px;
      line-height: 29px;
      padding-top: 60px;
      padding-bottom: 20px;
      padding-left: 137px !important;
      text-align: left;
  }

  .row.row-no-padding > [class*="col-12 complementary"]{
    padding-left: 137px !important;
    text-align: left;
  }

    .intro{
      font-family: Favorit Trial Std;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px; 
      font-size: 24px;
      line-height: 29px;
      padding: 30px 30px 0px 30px;
    }
  
.intro.intro1 {
    padding: 229px 30px 40px 30px;
    font-size: 38px;
    line-height: 50px;
}
  
  h2{
    z-index: 8;
    padding-top: 30px;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
  }
  #h2-latest-works{
    padding-bottom: 20px;
    font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  }
  h2#colophon {
    font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
    padding-top: 70px;
    text-align: center;
    padding-bottom: 20px;
  }

  button {
    width: 100%;
  }
  
  #latest-works div p{
    text-align: center;
    font-size: 32px;
    line-height: 42px;
    padding-top: 15px;
    margin:0px;
    
  }
  .work-details {
    font-size: 24px;
  line-height: 29px;
  padding: 0px 30px 0px 30px;
  }
  .button {
    padding: 50px 30px 32px 30px;
    font-size: 22px;
    line-height: 26px;
  }
  .colophon {
    padding: 10px 15px 0px 15px;
    margin:0px;
  }
  #design, #development, #copywriting, #typography, #technology, #moral-support {
  padding-top: 20px;
  }
  .colophon-headings {
    padding: 0px 30px 0px 30px;
    font-size: 26px;
    line-height: 34px;
    }
  
    }
