
h2#get-in-touch {
  padding-bottom: 52px;
  padding-top: 91px;
  font-family: PT Serif;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 42px;
	text-align: center;
}

.get-in-touch {
  /* align-items: center;
  justify-content: center; */
  
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 49px;
  line-height: 59px;
  padding-bottom: 75px;
  
  /* #202020 */
  
  color: #202020;
  }
  
  .contact {
  /* position: absolute;
  height: 137px;
  left: 243px;
  top: 4014px; */
  font-family: Favorit Trial Std;
  font-style: normal;
  font-weight: normal;
  font-size: 49px;
  line-height: 59px;
  /* #202020 */
  
  color: #202020;
  }
  #linkedin-contact:hover, #instagram-contact:hover {
    color: #001AFF;
    text-decoration: underline;
  }

  a:link.contact {
    color: #001AFF;
  }

  #collaborations {
    font-family: Favorit Trial Std;
    font-style: normal;
    font-weight: normal;
    font-size: 49px;
    line-height: 59px;
  }

  @media(min-width: 1200px){
.row-no-padding > [class*="col-"]{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
    #collaborations {
      padding: 0px 169px 73px 169px;
      /* padding: 0px 150px 73px 150px; */
    }
  #contact-me {    
    padding: 0px 169px 73px 169px;
  }
}

  @media(max-width:576px){
  #collaborations {
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 30px;
  }
  .get-in-touch {
    font-size: 32px;
line-height: 42px;
  }
  h2#get-in-touch {
padding-bottom: 32px;
margin: 0px;
  }
  #contact-me {
    font-size: 24px;
    line-height: 29px;
    /* padding: 0px 30px 30px 30px; */
  }
}

@media(min-width:1440px){

  h2#get-in-touch {
    padding-bottom: 60px;
  }
  #collaborations {
    padding: 0px 90px 73px 90px;
}
#contact-me {
  padding: 0px 90px 73px 90px;
}
}


/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #collaborations {
      font-size: 24px;
      line-height: 29px;
      padding-bottom: 30px;
    }
    .get-in-touch {
      font-size: 32px;
  line-height: 42px;
    }
    h2#get-in-touch {
  padding-bottom: 32px;
  margin: 0px;
    }
    #contact-me {
      font-size: 24px;
      line-height: 29px;
      /* padding: 0px 30px 30px 30px; */
    }
  }

  @media only screen and (device-width: 768px) {
#collaborations, #contact-me {
  font-size: 24px;
  line-height: 29px;
}
    }


    
@media only screen and (device-width: 1024px) {
  #collaborations, #contact-me {
  font-size: 24px;
  line-height: 29px;
}
    }