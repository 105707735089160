/* .clickable-logo {
	z-index: 19;
} */
 #logo {
	font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
    line-height: 19px;
	z-index: 19;
	color: #000000;
  } 

#logo a {
	color: #000000;
}
#logo a:hover {
	text-decoration: none;
	color: #001AFF;
}
  #logo.mobile-logo {
	  z-index: 19;
  }

#menu {
	z-index: 19;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
}

#menu .open {
	z-index: 19;
	font:white;
}
.mobileopen#logo a, .mobileopen#menu{
color: white;
}

.header {
	padding-top: 40px;
	z-index: 10;
}

.header#open {
	padding-top: 30px;
	z-index: 10;
}



  #open-menu > img {
	position: fixed;
	width: 126px;
	height: 126px;
  }

  .menu-clickable#menu:hover {
	stroke: #001AFF;
	font-size: normal;
  }
  .menu-clickable:hover #triangle, .menu-clickable:hover #inner-menu {
	stroke: #001AFF;
  }

  .menu-clickable:hover #inner-menu{
	  stroke-width: 0.5px;
  }

  .menu-about, .menu-works, .menu-contact {
	font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 70px;
	line-height: 84px;

	color: #FFFFFF;
	padding-bottom: 50px;
  }



  .menu-about {
	padding-top: 30vh;
  }

 .menu-social {
	font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
    position: relative;
	
	color: #FFFFFF;
 }


 .open {
	 z-index: 20px;
 }

 .menu-social > a {
	display: inline-block;
	padding-right: 20px;
  } 

@media(min-width:1200px){
	#open-menu {
		top: 0px;
		right: 0px;
		position: fixed;
		display: block;
	
		width: 57vw;
		height: 1024px;
		/* left: 608px;
		top: 0px; */
		z-index: 10; 
		
		/* Style */
		
		background: #001AFF;
	  }
	.header {
		padding-top: 30px;
		position: fixed;
		z-index: 10;
	}

	.header#open {
		/* padding-top: 40px; */
		z-index: 10;
		position: fixed;
	}
	
	#logo {
	padding-top: 40px;
	padding-left: 53px;
	}
	#menu {
		padding-right: 77px;
	}
	.menu-about, .menu-works, .menu-contact, .menu-social {
		left: 12%;
		position: relative;
	}
}

@media(min-width:1440px){
	.header {
		padding-top: 30px;
		position: fixed;
		z-index: 10;
	}

	.header#open {
		/* padding-top: 40px; */
		z-index: 10;
	}
	
	#logo {
	padding-top: 40px;
	padding-left: 90px;
	}
	#menu {
		padding-right: 40px;
	}
	.menu-about, .menu-works, .menu-contact, .menu-social {
		left: 12%;
		position: relative;
	}
}



@media(max-width:576px){
#open-menu {
	width: 100vw;
	height: 100vh;
	z-index: 10; 
	top: 0px;

    position: fixed;
	
	background: #001AFF;
}
.menu-about {
	padding-top: 20vh;
}
.menu-about, .menu-works, .menu-contact, .menu-social {
    position: relative;
    text-align: center;
}
}
/*   
  #menu {
	position: fixed;
	width: 126px;
	height: 126px; */
	/* left: 1270px;
	top: 25px; */
	/* z-index: 10;
  }
  
  #menu:hover #inner-menu {
	fill: none;
  }

  .open#menu {
	display: none
  }
  .open#menu #triangle {
	display: none
  }

  .open#menu #inner-menu {
	z-index: 1000;
	fill: white;
  }


/* 
  .menu-about {
	width: 490px;
	height: 95px;
	top: 233px;
  } */

  /* .menu-works {
	width: 490px;
	height: 95px;
	top: 366px;
  }

  .menu-contact {
	width: 490px;
	height: 95px;
	top: 500px;
  } */

  /* .menu-social {
	position: absolute;
	height: 24px;
	left: 122px;

	top: 653px;
	font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	
	color: #FFFFFF;
  }

  .menu-social > a {
	display: inline-block;
	padding-right: 20px;
  } */



/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

#open-menu {
	top: 0px;
	right: 0px;
	position: fixed;
	display: block;

	width: 57vw;
	height: 1024px;
	/* left: 608px;
	top: 0px; */
	z-index: 10; 
	
	/* Style */
	
	background: #001AFF;
  }
.header {
	padding-top: 30px;
	position: fixed;
	z-index: 10;
}

.header#open {
	/* padding-top: 40px; */
	z-index: 10;
	position: fixed;
}

#logo {
padding-top: 40px;
padding-left: 53px;
}
#menu {
	padding-right: 77px;
}
.menu-about, .menu-works, .menu-contact, .menu-social {
	left: 12%;
	position: relative;
}
  }


  @media only screen and (device-width: 1024px) {
	  
#open-menu {
	top: 0px;
	right: 0px;
	position: fixed;
	display: block;

	width: 57vw;
	height: 1024px;
	/* left: 608px;
	top: 0px; */
	z-index: 10; 
	
	/* Style */
	
	background: #001AFF;
  }
.header {
	padding-top: 30px;
	position: fixed;
	z-index: 10;
}

.header#open {
	/* padding-top: 40px; */
	z-index: 10;
	position: fixed;
}

#logo {
padding-top: 40px;
padding-left: 53px;
}
#menu {
	padding-right: 77px;
}
.menu-about, .menu-works, .menu-contact, .menu-social {
	left: 12%;
	position: relative;
}
  }


  @media only screen and (device-width: 768px) {
	  	  
#open-menu {
	top: 0px;
	right: 0px;
	position: fixed;
	display: block;

	width: 57vw;
	height: 1024px;
	/* left: 608px;
	top: 0px; */
	z-index: 10; 
	
	/* Style */
	
	background: #001AFF;
  }
.header {
	padding-top: 30px;
	position: fixed;
	z-index: 10;
}

.header#open {
	/* padding-top: 40px; */
	z-index: 10;
	position: fixed;
}

#logo {
padding-top: 40px;
padding-left: 53px;
}
#menu {
	padding-right: 77px;
}
.menu-about, .menu-works, .menu-contact, .menu-social {
	left: 12%;
	position: relative;
}
  }