footer {
	padding-top: 75px
}


.created-by {
    padding-bottom: 40px;
	padding-top: 31px;
	
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	
	text-align: center;
	
	color: #000000;
}

.back-to-top {
	font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #000000;
}

.arrow {
	width: 34px;
	height: 23px;
}

  .back-to-top a:link {
	color:black;
}

.back-to-top:hover .textup {
	color: #001AFF;
    text-decoration: underline;
  }


a:visited .textup {
	color:black;
    text-decoration: none;
}

.back-to-top:hover #upper, .back-to-top:hover #line{
	stroke: #001AFF;
}