

a:hover.no-style-anchor {
	color: #001AFF;
	text-decoration: none;
  }
.website a {
	color: #001AFF;
}
  .website a:hover {
	color: #001AFF;
  }
  
  a:hover.no-style-anchor #no-style-at-all-anchor {
	text-decoration: underline;
  }

  /* a:hover .goto  {
	text-decoration: underline;
} */

/* div.container.works {
	padding:0;
	margin:0;
} */


/* 
a #no-style-at-all-anchor:hover{
	color: green;
	text-decoration: none;
}

a .goto:hover{
	color: blue;
	text-decoration: underline;
} */

  img {
	width: 100%;
	}


  .work-description {
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

.work-description.one{
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
}

.work-description.two {
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}



.caption {
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
	padding-top: 11.5px;
	padding-bottom: 61px
}



#h2 {
	font-family: PT Serif;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 42px;
	text-align: center;
padding-bottom: 39px;
margin: 0px;
}

.navi {
	font-family: PT Serif;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 26px;
	padding-bottom: 15px;
	white-space: nowrap;
}

.goto {	
font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
white-space: nowrap;
}


.prev .navi {
	text-align: right;
}

.next .navi {
	text-align: left;
}
/* 
.intro-works {
	padding-top: 106px;
	padding-bottom: 155px;
} */

#category {
	font-family: PT Serif;
font-style: normal;
font-weight: normal;
font-size: 26px;
line-height: 34px;
padding-bottom: 3px;
}

#company {
font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
padding-bottom: 19px;
}

.works-intro-heading {
font-family: PT Serif;
font-style: italic;
font-weight: normal;
font-size: 18px;
line-height: 24px;
padding-bottom: 7px;
padding-top: 22px
}

.works-intro-details {
font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
}

.works-intro-details.website {
	padding-top: 22px;
	text-decoration: underline;
}

.sidebar-works {
		position: fixed;
		height: 45px;
		left: 33px;
		top: 424px;
		font-family: Favorit Trial Std;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		transform: rotate(-90deg);
		position: fixed;
		z-index: 10;
}

/* .row-no-padding > [class*="col-"]{
	padding-left: 0 !important;
	padding-right: 0 !important;
} */

/* .works-image {
	width: 794px;
} */

#website {
	padding-top: 10px;
	color: #001AFF;
}

a:link#website {
	color: #001AFF;
}

a:hover#website {
	color: #001AFF;
}






@media(min-width: 600px) { 
	.works-intro-details.website {
		padding-top: 22px;
		text-decoration: underline;
	}

	.work-description {
		/* padding: 0px 169px 95px 169px; */
		font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 43px;
	}
	
	.work-description.one{
	font-size: 36px;
	line-height: 43px;
	}
	
	.work-description.two {
		/* padding: 0px 169px 95px 169px; */
		font-family: Favorit Trial Std;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 43px;
	}	

}


@media(max-width:576px){
	#category, #company, .works-intro-heading, .works-intro-details {
		padding-left: 15px;
		padding-right: 15px;
	}
	/* .container{
		overflow: hidden;
		padding: 0px;
	} */
	  html body {
		padding:0;
	  }
	/* .row.row-no-padding > [class*="col-"]{
		padding-left: 15px !important;
		padding-right: 15px !important;
	} */
	.intro-works {
		padding: 0px 15px 40px 15px;
	}
    .work-description, .work-description.one, .work-description.two {
		font-size: 24px;
		line-height: 29px;
		padding: 0px 30px 52px 30px;
	}

	.work-description.one#intro-illu{
		padding-bottom: 10px;
	}

	/* .work-description.two {
		font-size: 24px;
		line-height: 29px;
		padding: 0px 45px 52px 45px;
	} */
	.caption {
		text-align: center;
		padding-bottom: 30px;
		padding-top: 12px;
	}
	.goto {
		font-size: 24px;
		line-height: 29px;
	}
	.navi {
		font-size: 20px;
		line-height: 26px;
	}
	.works > .navigation {
		padding-right: 19px;
	}
	.works > .navigation {
		padding-left: 19px;
	}
	h2 {
		padding-top: 30px;
	}
	#company {
		font-size: 36px;
		line-height: 43px;
		padding-bottom: 22px;
	}
	#category {
		padding-top: 59px;
		font-size: 26px;
		line-height: 34px;
	}
	img {
	width: 100%;
	}  

	.works-image, .main-image {
		width: 100%;
	}
	.navigation.main {
		padding-top: 60px;
	}
	.works-intro-details.website {
		padding-top: 22px;
		text-decoration: underline;
	}
}

@media(min-width: 992px) {
	
	.sidebar-works {
		position: fixed;
		height: 45px;
		left: 33px;
		top: 424px;
		font-family: Favorit Trial Std;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		transform: rotate(-90deg);
		position: fixed;
		z-index: 10;
	}
	.works-intro-details.website {
		padding-top: 22px;
		text-decoration: underline;
	}
	}


@media(min-width:1200px){
	#company {
		padding-bottom: 0px;
		}
	.works-intro-heading {
	padding-top: 30px
	}
	/* .main-image.rupp, .main-image.mycs {
		width: auto;
	} */
	.intro-works {
		padding: 232px 102px 155px 104px;
	}
	.works-intro-details.website {
		padding-top: 30px;
		text-decoration: underline;
	}
	.main-image {
		padding: 0px;
		width: 590px;
		margin-left: 73px;
	}
	
  .works-image {
    padding: 0px 203px 0px 203px !important;
 } 
 .works-image#illu-portrait1{
 padding: 0px 203px 0px 203px !important;
 }
 .works-image#illu-portrait{
    padding: 0px 277px 0px 277px !important;
	}
 .caption {
	padding: 11.5px 203px 60px 203px !important;
}

#illu-portrait-caption.caption {
	padding: 11.5px 277px 60px 277px !important;
}
.works-intro-heading {
	padding-bottom: 7px;
}
#category {
	padding-bottom: 3px;
}
/* #company {
	padding-bottom: 12px;
} */
.work-description {
	padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

.work-description.one{
    padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
}

.work-description.two {
	padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

.work-description.two.nuremberg {
	padding: 0px 203px 0px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

}

@media(min-width:1440px){
	.works-intro-heading {
		padding-top: 30px
		}
	.main-image {
		width: 710px;
		margin-left: 47px;
		padding-left: 0px;
		/* padding-right: 107px;
		padding-left: 36px; */
	}
	.work-description, .work-description.one {
		font-size: 50px;
		line-height: 60px;
		padding: 0px 243px 50px;
	}
	.work-description.two {
		font-size: 36px;
		line-height: 43px;
		padding: 0px 243px 50px;
	}
	.works-image {
		padding: 0px 243px 0px 243px !important;
	}
	.caption {
	padding: 11.5px 243px 60px 243px !important;
	}
	.intro-works {
		padding: 232px 0px 130px 124px;
	}
	.works-intro-details.website {
		padding-top: 22px;
		text-decoration: underline;
	}
}


@media only screen and (device-width: 1024px) {
	#company {
		padding-bottom: 0px;
		}
	.works-intro-heading {
	padding-top: 30px
	}
	/* .main-image.rupp, .main-image.mycs {
		width: auto;
	} */
	.intro-works {
		padding: 232px 102px 155px 104px;
	}
	.works-intro-details.website {
		padding-top: 30px;
		text-decoration: underline;
	}
	.main-image {
		padding: 0px;
		width: 516px;
		margin-left: 88px;
	}
	
  .works-image {
    padding: 0px 203px 0px 203px !important;
 } 
 .works-image#illu-portrait1{
 padding: 0px 321px 0px 321px !important;
 }
 .works-image#illu-portrait{
    padding: 0px 277px 0px 277px !important;
	}
 .caption {
	padding: 11.5px 203px 60px 203px !important;
}

#illu-portrait-caption.caption {
	padding: 11.5px 277px 60px 277px !important;
}
.works-intro-heading {
	padding-bottom: 7px;
}
#category {
	padding-bottom: 3px;
}
/* #company {
	padding-bottom: 12px;
} */
.work-description {
	padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

.work-description.one{
    padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
}

.work-description.two {
	padding: 0px 203px 95px 203px;
	font-family: Favorit Trial Std;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
}

}

@media only screen and (device-width: 768px) {
	#category, #company, .works-intro-heading, .works-intro-details {
		padding-left: 15px;
		padding-right: 15px;
	}
	/* .container{
		overflow: hidden;
		padding: 0px;
	} */
	  html body {
		padding:0;
	  }
	/* .row.row-no-padding > [class*="col-"]{
		padding-left: 15px !important;
		padding-right: 15px !important;
	} */
	.intro-works {
		padding: 158px 15px 40px 15px;
	}
    .work-description, .work-description.one, .work-description.two {
		font-size: 24px;
		line-height: 29px;
		padding: 0px 30px 52px 30px;
	}

	.work-description.one#intro-illu{
		padding-bottom: 10px;
	}

	/* .work-description.two {
		font-size: 24px;
		line-height: 29px;
		padding: 0px 45px 52px 45px;
	} */
	.caption {
		text-align: center;
		padding-bottom: 30px;
		padding-top: 12px;
	}
	.goto {
		font-size: 24px;
		line-height: 29px;
	}
	.navi {
		font-size: 20px;
		line-height: 26px;
	}
	.works > .navigation {
		padding-right: 19px;
	}
	.works > .navigation {
		padding-left: 19px;
	}
	h2 {
		padding-top: 30px;
	}
	#company {
		font-size: 36px;
		line-height: 43px;
		padding-bottom: 22px;
	}
	#category {
		padding-top: 59px;
		font-size: 26px;
		line-height: 34px;
	}
	img {
	width: 100%;
	}  

	.works-image {
		width: 100%;
	}
	.main-image {
		position: relative;
		width: 389px;
		top: 63px;
		right: -65px;

}
	.navigation.main {
		padding-top: 60px;
	}
	.works-intro-details.website {
		padding-top: 22px;
		text-decoration: underline;
	}
}